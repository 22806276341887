import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`The 6:00am class today is cancelled.  The Ville will open at 8:00am
for Open gym and  classes will be back to normal starting at 10:30am.`}</em></strong></p>
    <p>{`Warm up: Snatch Balace 5-5-5`}</p>
    <p>{`Power Snatch 5-5-5-5 to a 5RM`}</p>
    <p>{`then,`}</p>
    <p>{`42-Double Unders`}</p>
    <p>{`21-OHS (115/75, rx+ 135/95)`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-OHS`}</p>
    <p>{`18-Double Unders`}</p>
    <p>{`9-OHS`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Nov 26th.  For more info email
Eric at: fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      